import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import env from 'react-dotenv';

emailjs.init(env.REACT_APP_USER_ID);

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { feedback: '', name: '', email: '' };
  }
  // saves the user's name entered to state
  nameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  // saves the user's email entered to state
  emailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  // saves the user's message entered to state
  messageChange = (event) => {
    this.setState({ feedback: event.target.value });
  };

  //onSubmit of email form
  handleSubmit = (event) => {
    event.preventDefault();

    //This is a custom method from EmailJS that takes the information
    //from the form and sends the email with the information gathered
    //and formats the email based on the templateID provided.
    this.sendFeedback(env.REACT_APP_TEMPLATE_ID, {
      message: this.state.feedback,
      name: this.state.name,
      email: this.state.email,
    });
  };

  //Custom EmailJS method
  sendFeedback = (templateId, variables) => {
    emailjs
      .send(env.REACT_APP_SERVICE_ID, templateId, variables)
      .then((res) => {
        // Email successfully sent alert
        Swal.fire({
          title: 'Email Successfully Sent',
          icon: 'success',
        });
      })
      // Email Failed to send Error alert
      .catch((err) => {
        Swal.fire({
          title: 'Email Failed to Send',
          icon: 'error',
        });
        console.error('Email Error:', err);
      });
  };

  render() {
    return (
      <section id="contact">
        <div className="row section-head">
          <div className="two columns header-col">
            <h1>
              <span>Get In Touch.</span>
            </h1>
          </div>

          <div className="ten columns">
            <p className="lead">Get In Touch</p>
          </div>
        </div>

        <div className="row">
          <div className="eight columns">
            <form
              action=""
              method="post"
              id="contactForm"
              name="contactForm"
              onSubmit={this.handleSubmit}
            >
              <fieldset>
                <div>
                  <label htmlFor="name">
                    Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue=""
                    size="35"
                    id="name"
                    name="name"
                    onChange={this.nameChange}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="email">
                    Email <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue=""
                    size="35"
                    id="email"
                    name="email"
                    onChange={this.emailChange}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="contactSubject">Subject</label>
                  <input
                    type="text"
                    defaultValue=""
                    size="35"
                    id="contactSubject"
                    name="contactSubject"
                    onChange={this.handleChange}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="message">
                    Message <span className="required">*</span>
                  </label>
                  <textarea
                    cols="200"
                    rows="5"
                    id="message"
                    name="message"
                    onChange={this.messageChange}
                    required
                  ></textarea>
                </div>

                <div className="submit-container">
                  <input
                    className="submit"
                    type="submit"
                    value="Submit"
                    style={{
                      backgroundColor: 'black',
                      color: 'white',
                      cursor: 'pointer',
                      marginLeft: '165px',
                    }}
                  />
                  {/* <button className="submit" onClick={this.handleSubmit}>
                    Submit
                  </button> */}
                  <span id="image-loader">
                    <img alt="" src="images/loader.gif" />
                  </span>
                </div>
              </fieldset>
            </form>

            <div id="message-warning">Error</div>
            <div id="message-success">
              <i className="fa fa-check"></i>Your message was sent, thank you!
              <br />
            </div>
          </div>

          <aside className="four columns footer-widgets"></aside>
        </div>
      </section>
    );
  }
}

export default Contact;

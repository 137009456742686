import React, { Component } from 'react';
import './Media.css';

class Media extends Component {
  render() {
    var video = 'images/Yannis_Grigorakis_Animation_Demo_EXTENDED.mp3';
    return (
      <section id="media">
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className="twelve columns collapsed">
            <div className="columns media-item">
              <h1 className="headerTitle">Animation Demo</h1>
              <div className="overlay">
                <div
                  id="media-wrapper"
                  className="bgrid-quarters s-bgrid-thirds cf"
                >
                  <video width="900" height="500" controls className="demo">
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Media;

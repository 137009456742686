export const PortfolioImages = [
  {
    title: 'Importance of Being Earnest',
    category: '',
    image: 'images/portfolio/stage.jfif',
    url: '',
  },
  {
    title: "A Midsummer Night's Dream",
    category: '',
    image: 'images/portfolio/angry.jfif',
    url: '',
  },
  {
    title: 'The Merchant Of Venice',
    category: '',
    image: 'images/portfolio/standing.jfif',
    url: '',
  },
  {
    title: 'Star Wars - Episode IV',
    category: '',
    image: 'images/portfolio/headshot.jpg',
    url: '',
  },
  {
    title: 'Fargo',
    category: '',
    image: 'images/portfolio/main.jpg',
    url: '',
  },
];

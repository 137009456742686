import React, { Component } from 'react';
import './About.css';

class About extends Component {
  render() {
    if (this.props.data) {
      // var profilepic = 'images/portfolio/' + this.props.data.image;
      var bio = this.props.data.bio;
      // var phone = this.props.data.phone;
      // var email = this.props.data.email;
    }

    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            {/* <div className="imageContainer">
              <img className="profilePic" src={profilepic} alt="Profile Pic" />
            </div> */}
          </div>
          <div className="nine columns main-col">
            <h2>About</h2>

            <p>{bio}</p>
            {/* <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>Phone Number: +30 {phone}</span>
                  <br />
                  <span>Email: {email}</span>
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default About;

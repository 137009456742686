import React, { Component } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { PortfolioImages } from '../portfolioImages';
import './Portfolio.css';

class Portfolio extends Component {
  render() {
    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Check Out Some Pictures</h1>
            <div
              id="portfolio-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              <Carousel>
                {PortfolioImages.map((data, key) => {
                  return (
                    <div key={key}>
                      <img
                        src={data.image}
                        className="carouselImage"
                        alt={data.title}
                        key={key}
                      />
                      {/* <p className="legend">{data.title}</p> */}
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;

// {[
//   {
//     src: projects[0].image,
//     title: 'image title',
//     description: 'image description',
//   },
//   {
//     src: projects[1].image,
//     title: 'image title',
//     description: 'image description',
//   },
//   {
//     src: projects[2].image,
//     title: 'image title',
//     description: 'image description',
//   },
// ]}
